<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @action="(action) => action.handler({ from: value })"
    @onServiceSave="onSave"
  >
    <template v-slot:content="{}">
      <NewServiceFormVue
        ref="form"
        class="pa-4"
        v-model="form"
      ></NewServiceFormVue>
    </template>
  </AView>
</template>

<script>
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import NewServiceFormVue from "../../../../../../components/wad/organisms/services/forms/NewServiceForm.vue";
import { SERVICE_BUTTONS } from "../../constants/actions.gh";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    NewServiceFormVue,
    AView,
  },
  data: () => ({
    form: {},
  }),
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        SERVICE_BUTTONS.SAVE,
        this.value.permissions
      );
    },
  },
  methods: {
    onSave() {
      const valid = this.$refs.form.validate();
      if (valid)
        this.$store.dispatch("ServiceStore/CreateNewService", {
          from: this.value,
          newService: this.form,
        });
    },
  },
};
</script>