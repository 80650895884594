<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="new-service-col">
        <HelpFormInputVue
          dark
          v-model="form.name"
          :rules="serviceNameRules"
          label="Service Name"
          :helpText="'This is a service name that will be used to recognize the service.'"
          required
        ></HelpFormInputVue>

        <SelectorServiceType
          dark
          autoload
          v-model="form.type"
          :rules="serviceTypeRules"
          :helpText="'Please select the type of the service'"
          :label="'Service Type'"
          return-object
        ></SelectorServiceType>
      </v-col>
    </v-row>
  </v-form>
</template>
  
  
  <script>
import SelectorServiceType from "../../../atoms/common/autocompletes/SelectorServiceType.vue";
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      // ======rules
      serviceNameRules: [(v) => !!v || "Service Name is required"],
      serviceTypeRules: [(v) => !!v || "Service Type is required"],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorServiceType,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.form.previous_service_id = newVal?.prevService?.id;
        this.form.next_service_id = newVal?.nextService?.id;

        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>